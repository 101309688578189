import React from "react";
import BaseComponent from '../BaseComponent';

import MapPath from 'images/office-address-1.jpg';

class OfficeMap extends BaseComponent {
  render () {
    return (
      <div className="hide-overflow" data-aos="fade-up">
        <img src={MapPath} className="company-map" />
      </div>
    );
  }
}

export default OfficeMap
