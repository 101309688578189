import React from "react";
import BaseComponent from '../BaseComponent';
import Button from '../Common/Button';
import Caution from '../Footer/Caution';

import ProductImgPath from 'images/device2.png';

class OurProduct extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      content: 'New' // New or Old
    };
  }
  getCategory() {
    if (this.state.content === 'New') {
      return null;
    }
    return (
      <h4 className="light-color mb-1" data-aos="fade-up">Our Product</h4>
    );
  }
  getTitle() {
    return (
      <>
        <h1 className="d-none d-md-block gw-heading bold mb-5" data-aos="fade-up">
          No Blood, No Pain.
        </h1>
        <h1 className="d-block d-md-none gw-subheading bold mb-5" data-aos="fade-up">
          No Blood,<br /> No Pain.
        </h1>
      </>
    );
  }
  getSubTitle() {
    return (
      <h1 className='display-5 mb-5' data-aos="fade-up">
        An investigational needle-free continuous glucose monitoring wearable.
      </h1>
    );
  }
  getContent() {
    return (
      <div className="pb-5">
        <p className="mt-5 gw-paragraph" data-aos="fade-up">
          This device is designed to measure molecules like glucose on the
          surface of your skin using nanotechnology.
        </p>
      </div>
    );
  }
  getButton() {
    if (this.state.content === 'New') {
      return null;
    }
    return (
      <p className="lead mt-5" data-aos="fade-up">
        <Button name="Learn More" />
      </p>
    );
  }
  render() {
    return (
      <div className="pad-2 section dark-grey-background pt-5 pb-5 hide-overflow-x" id="our_product">
        <div className="container height-of-parent">
          <div className="row align-items-center justify-content-center height-of-parent">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-block d-md-none" data-aos="fade-up">
              <div className="image-container pt-5 pb-5">
                <img src={ProductImgPath} className='img-fluid our-product mt-5 mb-5' />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="mt-5 mb-5 white-color">
                {this.getCategory()}
                {this.getTitle()}
                {this.getSubTitle()}
                {this.getContent()}
                {this.getButton()}
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-none d-md-block" data-aos="fade-up">
              <div className="image-container pt-5 pb-5">
                <img src={ProductImgPath} className='img-fluid our-product mt-5 mb-5' />
              </div>
            </div>
          </div>
        </div>
        <Caution />
      </div>
    );
  }
}

export default OurProduct
