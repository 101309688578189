import React from "react";
import BaseComponent from '../BaseComponent';
import Person from './Person';
import PersonV2 from './PersonV2';

import Douglas from 'images/board-douglas.png';
import Eric from 'images/board-eric.png';
import Mark from 'images/board-mark.jpeg';
import Tejas from 'images/board-tejas.png';
import Ursheet from 'images/board-ursheet.jpeg';
import Woody from 'images/board-woody.jpeg';

class BoardMembers extends BaseComponent {
  render() {
    return (
      <div className="light-grey-background pt-5 pb-5">
        <div className="container mt-5 mb-5">
          <div className="display-4 bold text-center dark-green" data-aos="fade-up">
            Board Members
          </div>
          <div className="ps-wrap mt-4">
            <div className="d-none d-lg-block">
              <div className="row ps-filter person-alignment">
                <PersonV2 headshot={Ursheet} name="Ursheet Parikh" designation="Partner, Mayfield Fund" linkedin="https://www.linkedin.com/in/uparikh" />
                <PersonV2 headshot={Douglas} name="Douglas Crawford" designation="Managing Partner, Mission BioCapital" linkedin="https://www.linkedin.com/in/douglas-crawford-3826b21" />
                <PersonV2 headshot={Woody} name="Woody Scal" designation="Independent Board Member, former CBO Fitbit" linkedin="https://www.linkedin.com/in/woody-scal-a859203" />
              </div>
              <div className="row ps-filter person-alignment">
                <PersonV2 headshot={Eric} name="Eric Linsley" designation="Managing General Partner, BioInnovation Capital" linkedin="https://www.linkedin.com/in/ericlinsley" />
                <PersonV2 headshot={Tejas} name="Tejas Maniar" designation="Operating Partner, Mayfield Fund" linkedin="https://www.linkedin.com/in/tejasmaniar" />
                <PersonV2 headshot={Mark} name="Mark Goldstein" designation="Managing Partner, Builders VC" linkedin="https://www.linkedin.com/in/mgoldstein1" />
              </div>
            </div>
            <div className="d-block d-lg-none">
              <div className="row ps-filter person-alignment">
                <PersonV2 headshot={Ursheet} name="Ursheet Parikh" designation="Partner, Mayfield Fund" linkedin="https://www.linkedin.com/in/uparikh" />
                <PersonV2 headshot={Douglas} name="Douglas Crawford" designation="Managing Partner, Mission BioCapital" linkedin="https://www.linkedin.com/in/douglas-crawford-3826b21" />
                <PersonV2 headshot={Woody} name="Woody Scal" designation="Independent Board Member, former CBO Fitbit" linkedin="https://www.linkedin.com/in/woody-scal-a859203" />
                <PersonV2 headshot={Eric} name="Eric Linsley" designation="Managing General Partner, BioInnovation Capital" linkedin="https://www.linkedin.com/in/ericlinsley" />
                <PersonV2 headshot={Tejas} name="Tejas Maniar" designation="Operating Partner, Mayfield Fund" linkedin="https://www.linkedin.com/in/tejasmaniar" />
                <PersonV2 headshot={Mark} name="Mark Goldstein" designation="Managing Partner, Builders VC" linkedin="https://www.linkedin.com/in/mgoldstein1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BoardMembers
