import React from "react";
import BaseComponent from './BaseComponent';
import NavBar from './Header/NavBar';
import Banner from './Banner/Banner';
import ContactForm from './ConnectPage/ContactForm';
import ContactInfo from './ConnectPage/ContactInfo';
import OfficeMap from './ConnectPage/OfficeMap';
import StayConnected from './Footer/StayConnected';
import Footer from './Footer/Footer';

class ConnectPage extends BaseComponent {
  render () {
    return (
      <div>
        <NavBar currentPage='' />
        <Banner category='' heading='Privacy Policy' />
        <div className="container mt-5 mb-5">
          <div className="row pad-2 pt-5 dark-green">
            <p>GraphWear Technologies, Inc. (“GraphWear,” “we,” “our,” and/or “us”) values the privacy of individuals who use our website and related services (collectively, our “Services”). This privacy policy (the “Privacy Policy”) explains how we collect, use, and share information from users of our Services (“Users”) or their devices. By using or accessing our Services, you agree to the collection, use, disclosure, and procedures this Privacy Policy describes.</p>
            <h1 className="mt-4 mb-3">Information We Collect</h1>
            <p>We collect a variety of information from or about you or your devices from various sources, as described below.</p>

            <p>If you do not provide your information when requested, you may not be able to use our Services if that information is necessary to provide our Services or if we are legally required to collect it.</p>

            <h2 className="mt-4 mb-3">A. Information You Provide to Us</h2>
            <p><b>Sign Up Information.</b> If you sign up to explore partnership opportunities with us or to receive notifications or updates about our Services, we ask you for your name, email address, phone number, organization, position, and information about your areas of interest.</p>

            <p><b>Communications.</b> If you contact us directly, we may receive additional information about you. For example, when you contact us for support, we will receive your name, email address, phone number, organization, information about your areas of interest, the contents of a message or attachments that you may send to us, and other information you choose to provide. When we send you emails, we may track whether you open them to learn how to deliver a better customer experience and improve our Services.</p>

            <p><b>Careers.</b> If you make inquiries concerning career opportunities or decide that you wish to apply for a job with us, you may submit your contact information and information about your work experience and education online. We will collect the information you choose to provide on your resume, such as your education and employment experience. You may also apply through our job application submission form provided by a third-party service provider. If you do so, we collect the information you make available to us through your application submission, such as your resume, links to other online profiles, and other information you choose to provide.</p>

            <h2 className="mt-4 mb-3">B. Information We Collect When You Use Our Services</h2>
            <p><b>Device Information.</b> We receive information about the device and software you use to access our Services, including internet protocol (“IP”) address, web browser type, operating system version, and unique device identifiers.</p>

            <p><b>Usage Information.</b> To help us understand how you use our Services and to help us improve them, when you use our Services, we automatically receive information about your interactions with our Services, like the pages, products, or other content you view, pages you request, the websites you visited before using our Services, the websites and search terms that brought you to our Services, and the dates, times and durations of your visits.</p>

            <p><b>Location Information.</b> When you use our Services, we infer the general physical location of your device and the geographic regions our Users come from. For example, your IP address may indicate your general geographic region.</p>

            <p><b>Information from Cookies and Similar Technologies.</b> We and third-party partners collect information using cookies, pixel tags, or similar technologies. Our third-party partners, such as analytics and advertising partners, may use these technologies to collect information about your online activities over time and across different services. Cookies are small text files containing a string of alphanumeric characters. We may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to our Services.</p>

            <p>Please review your web browser’s “Help” file to learn the proper way to modify your cookie settings. Please note that if you delete or choose not to accept cookies from our Services, you may not be able to utilize the features of our Services to their fullest potential.</p>

            <h2 className="mt-4 mb-3">C. Information We Receive from Third Parties</h2>
            <p><b>Third-Party Services.</b> You may also post photos, comments, or reviews on our pages available through third-party platforms, including Facebook and Instagram. If you do so, we and other users may be able to view the information you make available through these third-party platforms.</p>

            <p><b>Other Third Parties.</b> We may receive additional information about you from third parties such as data or marketing partners and combine it with other information we have about you.</p>

            <h1 className="mt-4 mb-3">How We Use the Information We Collect</h1>
            <p>We use the information we collect:</p>
            <ul>
              <li>To provide, maintain, improve, and enhance our Services;</li>
              <li>To communicate with you, provide you with updates and other information relating to our Services, provide information that you request, respond to comments and questions, and otherwise provide customer support;</li>
              <li>For marketing purposes, such as developing and providing promotional and advertising materials that may be useful, relevant, valuable or otherwise of interest to you;</li>
              <li>To generate analytics about how Users interact with our Services;</li>
              <li>To de-identify and aggregate information collected through our Services and use it for our business purposes;</li>
              <li>To find and prevent fraud, and respond to trust and safety issues that may arise;</li>
              <li>For compliance purposes, including enforcing our legal rights, or as may be required by applicable laws and regulations or requested by any judicial process or governmental agency; and</li>
              <li>For other purposes for which we provide specific notice at the time the information is collected.</li>
            </ul>
            <h1 className="mt-4 mb-3">How We Share the Information We Collect</h1>
            <p>We may share or otherwise disclose information we collect from or about you as described below or otherwise disclosed to you at the time of collection.</p>

            <p><b>Partners and Affiliates.</b> We may share any information we receive with our partners, subsidiaries, and affiliates for any of the purposes described in this Privacy Policy.</p>

            <p><b>Vendors and Service Providers.</b> We may share any information we receive with contractors, vendors, service providers, and other third parties retained in connection with the provision of our Services.</p>

            <p><b>Marketing.</b> We do not rent, sell, or share information about you with nonaffiliated companies for their direct marketing purposes, unless we have your permission.</p>

            <p><b>Analytics Partners.</b> We use analytics services such as Google Analytics to collect and process certain analytics data. These services may also collect information about your use of other websites, apps, and online resources. You can learn about Google’s practices by going to https://www.google.com/policies/privacy/partners/, and opt-out of them by downloading the Google Analytics opt-out browser add-on, available at https://tools.google.com/dlpage/gaoptout.</p>

            <p><b>As Required by Law and Similar Disclosures.</b> We may access, preserve, and disclose information about you if we believe doing so is required or appropriate to: (a) comply with law enforcement requests and legal process, such as a court order or subpoena; (b) respond to your requests; or (c) protect your, our, or others’ rights, property, or safety.</p>

            <p><b>Merger, Sale, or Other Asset Transfers.</b> We may disclose and transfer information about you to service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a corporate transaction in which we are acquired by or merged with another company or we sell, liquidate, or transfer all or a portion of our business or assets.</p>

            <p><b>Consent.</b> We may also disclose information from or about you or your devices with your permission.</p>

            <h1 className="mt-4 mb-3">Your Choices</h1>
            <p><b>Marketing Communications.</b> You can unsubscribe from our promotional emails via the link provided in the emails. Even if you opt out of receiving promotional messages from us, you will continue to receive administrative messages from us.</p>

            <p><b>Do Not Track.</b> There is no accepted standard on how to respond to Do Not Track signals, and we do not respond to such signals.</p>

            <h1 className="mt-4 mb-3">Third Parties</h1>
            <p>Our Services may contain links to other websites, products, or services that we do not own or operate. We are not responsible for the privacy practices of these third parties. Please be aware that this Privacy Policy does not apply to your activities on these third-party services or any information you disclose to these third parties. We are not responsible for the privacy practices or the content of such sites. If you have any questions about how these other sites use information from or about you, you should contact them directly. We encourage you to read their privacy policies before providing any information to them.</p>

            <h1 className="mt-4 mb-3">Security</h1>
            <p>We make reasonable efforts to protect information from or about you by using technical and organizational safeguards designed to improve the security of the information we maintain. However, as our Services are hosted electronically, we can make no guarantees as to the security or privacy of your information.</p>

            <h1 className="mt-4 mb-3">Children’s Privacy</h1>
            <p>We do not knowingly collect, maintain, or use personal information from children under 13 years of age, and no part of our Services are directed to children. If you learn that a child has provided us with personal information in violation of this Privacy Policy, please alert us at privacy@graphwear.co.</p>

            <h1 className="mt-4 mb-3">International Visitors</h1>
            <p>Our Services are hosted in the United States and intended for visitors located within the United States. If you choose to use our Services from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring information from or about you or your devices outside of those regions to the United States for storage and processing. Also, we may transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating our Services. By providing any information, including information from or about you or your devices, on or to our Services, you consent to such transfer, storage, and processing.</p>

            <h1 className="mt-4 mb-3">Changes to This Privacy Policy</h1>
            <p>This Privacy Policy may be revised from time to time. We will post any adjustments to the Privacy Policy on this page, and the revised version will be effective as of the last updated date. If we materially change the ways in which we use or share information from or about you or your devices previously collected from you through our Services, we will make reasonable efforts to notify you of the changes through our Services, by email, or other communication.</p>

            <h1 className="mt-4 mb-3">Contact Information</h1>
            <p>If you have any questions or concerns about this Privacy Policy, please contact GraphWear Technologies Inc at privacy@graphwear.co or write to us at 953 Indiana St San Francisco, CA 94107.</p>
          </div>
        </div>
        <StayConnected />
        <Footer />
      </div>
    );
  }
}

export default ConnectPage
