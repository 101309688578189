import React from "react";
import BaseComponent from './BaseComponent';
import NavBar from './Header/NavBar';
import AboutBanner from './Banner/AboutBanner';
import AboutTeam from './About/AboutTeam';
import SelectedInvestors from './About/SelectedInvestors';
import LeadershipTeam from './About/LeadershipTeam';
import BoardMembers from './About/BoardMembers';
import StayConnected from './Footer/StayConnected';
import Footer from './Footer/Footer';

class AboutPage extends BaseComponent {
  render () {
    return (
      <div>
        <NavBar currentPage='about' />
        <AboutBanner />
        <AboutTeam />
        <SelectedInvestors />
        <LeadershipTeam />
        <BoardMembers />
        <StayConnected />
        <Footer />
      </div>
    );
  }
}

export default AboutPage
