import React from "react";
import BaseComponent from '../BaseComponent';
import 'stylesheets/person.css'

class PersonV3 extends BaseComponent {
  render() {
    return (
      <div className='col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 light-grey-background p-5'>
        <a className='person-v2' href={this.props.linkedin} target="_blank">
          <div className="profile-container">
            <img className="profile-img" src={this.props.headshot} alt="" />
          </div>
          <div className="person-description-v2">
            <h1>{this.props.name}</h1>
            <p>{this.props.designation}</p>
          </div>
        </a>
      </div>
    );
  }
  openLinkedIn() {
    window.open(this.props.linkedin, '_blank').focus();
  }
}

export default PersonV3
