import React from "react";
import BaseComponent from '../BaseComponent';
import SocialIcon from './SocialIcon';
import Button from '../Common/Button';
import Caution from '../Footer/Caution';

import BgPath from 'images/banner-web.jpg';
import BgPathMobile from 'images/banner-mobile.jpg';

class MainBanner extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      texts: [
        "Pain Free",
        "Continuous",
        "Affordable"
      ],
      typingSpeed: 200,
      stillDuration: 1500,
      erasingSpeed: 100,
      tagLine: 'Static' // Static or Animated
    };
    // The first no-needle no-blood platform to monitor disease
  }
  tagLine() {
    return (
      <div className="flex-row pad-2">
        <h1 className="d-none d-md-block gw-dash-heading bold mb-5 dark-green" data-aos="fade-up">
          Designed to monitor your glucose without breaking the skin.
        </h1>
        <h1 className="d-block d-md-none gw-largetitle bold mb-5 dark-green" data-aos="fade-up">
          Designed to monitor your glucose without breaking the skin.
        </h1>
        <p data-aos="fade-up">
          <Button name="Find Out How" prepend={<i className="bi bi-arrow-down"></i>} onClick={this.props.scrollToNextSection} />
        </p>
      </div>
    );
  }
  render() {
    var bgStyle = {
      background: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${BgPath}) no-repeat center center`,
      backgroundSize: 'cover'
    };
    var bgMobileStyle = {
      background: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${BgPathMobile}) no-repeat center center`,
      backgroundSize: 'cover'
    };
    return (
      <>
        <div className="d-none d-md-block fullscreen section min-480h main-banner hide-overflow-x" style={bgStyle}>
          <div className="container height-of-parent">
            <div className="flex-center text-center">
              {this.tagLine()}
            </div>
          </div>
          <Caution />
        </div>
        <div className="d-block d-md-none fullscreen section min-480h main-banner hide-overflow-x" style={bgMobileStyle}>
          <div className="container height-of-parent">
            <div className="flex-center text-center">
              {this.tagLine()}
            </div>
          </div>
          <Caution />
        </div>
      </>
    );
  }
  componentDidMount() {
    if (this.state.tagLine === 'Static') {
      return;
    }
    const span = document.querySelector('.banner-span');
    this.typingEffect(span, 0);
  }
  typingEffect(element, textIndex) {
    textIndex = textIndex % this.state.texts.length;
    let text = this.state.texts[textIndex];
    var i = 0;
    const self = this;
    var timer = setInterval(function() {
      if (i < text.length) {
        element.append(text.charAt(i))
        i++;
      } else {
        clearInterval(timer);
        timer = setInterval(function() {
          clearInterval(timer);
          self.eraseEffect(element, textIndex);
        }, self.state.stillDuration);
      }
    }, this.state.typingSpeed);
  }
  eraseEffect(element, textIndex) {
    var i = element.innerHTML.length;
    const self = this;
    var timer = setInterval(function() {
      if (i >= 0) {
        var str = element.innerHTML;
        element.innerHTML = str.slice(0, str.length - 1);
        i--;
      } else {
        clearInterval(timer);
        self.typingEffect(element, textIndex + 1);
      }
    }, this.state.erasingSpeed);
  }
}

export default MainBanner
