import React from "react";
import BaseComponent from '../BaseComponent';

import LogoPath from 'images/graphwear-logo.png';

class NavBarLogo extends BaseComponent {
  render () {
    return (
      <a className="navbar-brand shadowfilter" onClick={this.goto} style={{ cursor: "pointer" }}>
        <img src={LogoPath} width='90px' height='67px' alt="GraphWear Logo" loading='lazy' />
      </a>
    );
  }
}

export default NavBarLogo
