import React from "react";
import BaseComponent from '../BaseComponent';

class Notice extends BaseComponent {
  render() {
    return (
        <div className="container hide-overflow-x">
            <div className="row height-of-parent mt-5 pad-2">
                <h1 className="gw-subtitle mb-5 dark-green" data-aos="fade-up">
                    Notice
                </h1>
                <p className='fine-print gw-lighter pb-2 dark-green' data-aos="fade-up">
                    In compliance with federal law, all persons hired will be required to verify identity and eligibility to work in the United States and to complete the required employment eligibility verification form upon hire. 
                </p>
                <p className='fine-print gw-lighter pb-2 dark-green' data-aos="fade-up">
                    Due to the nature of our business and the role, please note that you may be required to show current proof of vaccination against certain diseases, including COVID-19. 
                </p>
                <p className='fine-print gw-lighter pb-2 dark-green' data-aos="fade-up">
                    GraphWear Technologies Inc., is an Equal Employment Opportunity Employer. We provide equal employment opportunities to all qualified applicants and employees, and prohibit discrimination and harassment of any type, without regard to race, sex, pregnancy, sexual orientation, gender identity, national origin, color, age, religion, protected veteran or disability status, genetic information or any other status protected under federal, state, or local applicable laws.
                </p>
                <p className='fine-print gw-lighter pb-2 dark-green' data-aos="fade-up">
                    GraphWear Technologies Inc. will consider qualified applicants with a criminal history pursuant to the California Fair Chance Act. You do not need to disclose your criminal history or participate in a background check until a conditional job offer is made to you. After making a conditional offer and running a background check, if GraphWear Technologies Inc. is concerned about conviction that is directly related to the job, you will be given the chance to explain the circumstances surrounding the conviction, provide mitigating evidence, or challenge the accuracy of the background report. Find out more about the Fair Chance Act by visiting the California Department of Fair Employment and Housing’s Fair Chance Act webpage.
                </p>
            </div>
        </div>
    );
  }
}

export default Notice
