import React from "react";
import BaseComponent from '../BaseComponent';
import Button from '../Common/Button';

import ImagePath from 'images/team1.jpg';

class AboutUs extends BaseComponent {
  render() {
    return (
      <div className="container hide-overflow-x transparent-background">
        <div className="row align-items-center justify-content-center height-of-parent">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pad-2" data-aos="fade-up">
            <img src={ImagePath} className='img-fluid' />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pad-2 pt-5 pb-5">
            <div className="mt-5 mb-5">
              { /* <h4 className="light-color mb-1" data-aos="fade-up">About Us</h4> */ }
              <div className="display-4 mb-4 dark-green" data-aos="fade-up">
                About Us
              </div>
              <p className='mb-5 dark-color' data-aos="fade-up">
                We founded GraphWear to diagnose and monitor chronic conditions without any blood or urine.
                We understand the pain of managing chronic conditions. We understand the struggles of
                constantly being pricked by needles. We know how expensive and painful the available
                solutions are.
              <br />
                We are excited to bring you a revolutionary technology to continuously monitor glucose
                levels without any needles. We are working around the clock to get bios into your hands
                so you may live a life without needles.
              </p>
              <p className="lead mt-5" data-aos="fade-up">
                <Button name="Learn More" href="/about" />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs
