import React from "react";
import BaseComponent from '../BaseComponent';

class ProductItem extends BaseComponent {
  render() {
    return (
      <div className={`col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pad-2 text-center pt-5 mt-5 ${this.props.class}`}>
        <img src={this.props.image} className='img-fluid' data-aos="fade-up" ata-aos-delay={this.props.delay} />
        <div className="card__text pt-5 pb-5">
          <div className="mt-5">
            <div className="gw-subtitle dark-green" data-aos="fade-up"  ata-aos-delay={this.props.delay + 50}>
              {this.props.heading}<br />
              {this.props.heading2}
            </div>
            <div className='mt-4 pt-2 dark-color' data-aos="fade-up" ata-aos-delay={this.props.delay + 100}>
              {this.props.body}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductItem
