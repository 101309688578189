import React from "react";
import BaseComponent from '../BaseComponent';
import MenuButton from './MenuButton';
import NavBarLogo from './NavBarLogo';

import NavLogoPath from 'images/graphwear-logo.png';

class NavBar extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuClass: 'hidden',
      menuIconClass: ''
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }
  render () {
    var homeBtnTheme = this.props.currentPage == 'home' ? 'btn-success' : 'btn-blank';
    var aboutBtnTheme = this.props.currentPage == 'about' ? 'btn-success' : 'btn-blank';
    var careersBtnTheme = this.props.currentPage == 'careers' ? 'btn-success' : 'btn-blank';
    var connectBtnTheme = this.props.currentPage == 'connect' ? 'btn-success' : 'btn-blank';
    var listItemClass = 'list-group-item flex-center no-border pt-1 pb-1';
    return (
      <div className='nav_bar'>
        <div className='container'>
          <div className='navbar pad-2'>
            <NavBarLogo href='/' logoPath={NavLogoPath} />
            <nav className="navbar-nav pull-right">
              <div className="btn-toolbar d-none d-sm-block" role="toolbar">
                <MenuButton name='Home' href='/' btnTheme={homeBtnTheme} />
                <MenuButton name='About' href='/about' btnTheme={aboutBtnTheme} />
                <MenuButton name='Careers' href='/careers' btnTheme={careersBtnTheme} />
                <MenuButton name='Connect' href='/connect' btnTheme={connectBtnTheme} />
              </div>
              <div className="btn-toolbar d-block d-sm-none">
                <button id="nav-icon" className={`btn btn-blank ${this.state.menuIconClass}`} onClick={this.handleMenuClick}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </nav>
          </div>
        </div>
        <div className="container d-block d-sm-none">
          <div className={`row justify-content-center flex-vcenter pb-4 ${this.state.menuClass}`}>
            <ul className="list-group">
              <li className={listItemClass}><MenuButton name='Home' href='/' btnTheme={homeBtnTheme} /></li>
              <li className={listItemClass}><MenuButton name='About' href='/about' btnTheme={aboutBtnTheme} /></li>
              <li className={listItemClass}><MenuButton name='Careers' href='/careers' btnTheme={careersBtnTheme} /></li>
              <li className={listItemClass}><MenuButton name='Connect' href='/connect' btnTheme={connectBtnTheme} /></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  handleMenuClick(event) {
    this.setState(prevState => ({
      menuClass: prevState.menuClass === 'hidden' ? '' : 'hidden',
      menuIconClass: prevState.menuIconClass === 'open' ? '' : 'open'
    }))
  }
}

export default NavBar
