import React from "react";
import BaseComponent from '../BaseComponent';

class MenuButton extends BaseComponent {
  render () {
    var btnTheme = "btn-success";
    if (this.props.btnTheme) {
      btnTheme = this.props.btnTheme
    }
    var onClick = this.goto;
    if (this.props.onClick) {
      onClick = this.props.onClick;
    }
    return (
      <button type="button" className={`btn ${btnTheme} btn-lg btn-rounded`} onClick={onClick}>{this.props.name}</button>
    );
  }
}

export default MenuButton
