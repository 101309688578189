import React from "react";
import BaseComponent from '../BaseComponent';

class Button extends BaseComponent {
  render () {
    var rounded = 'btn-rounded';
    if (this.props.rounded) {
      rounded = this.props.rounded;
    }
    var btnStyle = 'btn-outline-success';
    if (this.props.btnStyle) {
      btnStyle = this.props.btnStyle
    }
    var onClick = this.goto;
    if (this.props.onClick) {
      onClick = this.props.onClick;
    }
    var prepend = "";
    var space = "";
    if (this.props.prepend) {
      prepend = this.props.prepend;
      space = " ";
    }
    var type = 'button';
    if (this.props.type) {
      type = this.props.type;
    }
    return (
      <button type={type} name={this.props.name} className={`btn ${btnStyle} btn-outline btn-lg btn-xl ${rounded}`} onClick={onClick}>
        {prepend}{space}{this.props.name}
      </button>
    );
  }
}

export default Button
