import React from "react";
import BaseComponent from '../BaseComponent';
import NewsCard from './NewsCard';

class NewsSection extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      news: [
        {
          category: 'News',
          title: 'GraphWear Brings On Former Dexcom and Fitbit Veterans To Scale First-of-its-Kind Health Monitoring Platform',
          body: 'Dana Cambra and Woody Scal will serve as key players in driving the companys mission forward as GraphWear develops its non-invasive disease management platform.',
          link: 'https://www.webwire.com/ViewPressRel.asp?aId=285400'
        },
        {
          category: 'Series B',
          title: 'GraphWear closes $20.5M Series B for a needle-free, nanotech-powered glucose monitor',
          body: 'This Series B round is a vote of confidence by investors in GraphWear’s approach: to monitor key metrics in the body, like glucose, without breaking the skin at all.',
          link: 'https://techcrunch.com/2021/10/04/graphwear-closes-20-5m-series-b-for-a-needle-free-nanotech-powered-glucose-monitor/'
        },
        {
          category: 'Series A',
          title: 'GraphWear Technologies raises $4.2M Series A',
          body: 'The Philly-born startup with ties to Dreamit is finding new applications for its technology.',
          link: 'https://technical.ly/philly/2018/04/17/graphwear-technologies-4m-series-a/'
        },
        {
          category: 'News',
          title: 'The Final Frontier for Diagnostics',
          body: 'GraphWear takes on sweat!',
          link: 'https://qb3.org/news/2017/11/14/graphwear-takes-on-sweat-the-final-frontier-for-diagnostics'
        },
        {
          category: 'News',
          title: 'GraphWear pilots sweat sensor patch with NFL team',
          body: 'GraphWear is also developing a patch to use sweat to assess glucose levels for diabetes patients.',
          link: 'https://medcitynews.com/2016/10/graphwear-sweat-sensor-wearable-and-nfl/'
        },
        {
          category: 'News',
          title: 'Philadelphia-based digital health accelerator DreamIt Health has announced its third class',
          body: 'GraphWear is innovating new kinds of sensors that can detect blood sugar',
          link: 'https://www.mobihealthnews.com/45343/new-dreamit-class-targets-epilepsy-cancer-concussions-and-more'
        },
        {
          category: 'Series A',
          title: 'The Funded: 5 Bay Area startups raise $50M at week\'s end',
          body: 'GraphWear Technologies Inc., San Francisco, $4.2 million',
          link: 'https://www.bizjournals.com/sanjose/news/2018/04/20/the-funded-5-bay-area-startups-raise-50m-at-weeks.html'
        },
        {
          category: 'News',
          title: 'Sweat Sensor Patch With NFL Team',
          body: 'Beyond Sweat: Hydration, Glucose, and More',
          link: 'https://www.boldbusiness.com/health/sensor-diagnoses-diseases-from-sweat/'
        },
        {
          category: 'News',
          title: 'GraphWear SweatSmart patch will keep NFL players hydrated',
          body: 'Sweat sensor to help make sure players are fully fuelled for training and game time',
          link: 'https://www.wareable.com/wearable-tech/graphwear-sweat-sensor-patch-used-in-nfl-3375'
        },
        {
          category: 'News',
          title: 'Global Diabetes Monitoring Devices Market (2020 to 2030) - Industry Trends and Global Forecasts',
          body: 'Diabetes is one of the seven leading causes of death and disability across the world',
          link: 'https://www.globenewswire.com/en/news-release/2021/01/06/2154128/28124/en/Global-Diabetes-Monitoring-Devices-Market-2020-to-2030-Industry-Trends-and-Global-Forecasts.html'
        },
      ]
    };
  }
  getNewsCards() {
    var delay = 0;
    var elements = [];
    for (var i = 0; i < this.state.news.length; i++) {
      var news = this.state.news[i];
      elements.push(
        <NewsCard animationDelay={delay} data={news} key={i} />
      )
      delay += 100;
    }
    return elements;
  }
  render() {
    return (
      <div className="pt-5 pb-5">
        <div className="container">
          <div className="display-4 text-center dark-green mt-5 mb-4" data-aos="fade-up">
            Latest From Us
          </div>
        </div>
        <div className="container news-section mb-5">
          <div className="glider mt-4 mb-5 news-cards" id="glider">
            {this.getNewsCards()}
          </div>
          <div className="input-group pad-2">
            <div className="horizontal-bar-container flex-vcenter">
              <hr className="horizontal-bar" />
              <hr className="heading" />
            </div>
            <button aria-label="Previous" className="glider-prev">❮</button>
            <button aria-label="Next" className="glider-next">❯</button>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    new Glider(document.querySelector('.glider'), {
      slidesToShow: 'auto',
      itemWidth: 260,
      exactWidth: true,
      dragVelocity: 1,
      draggable: true,
      dots: '.dots',
      arrows: {
        prev: '.glider-prev',
        next: '.glider-next'
      }
    });
    var element = document.getElementById('glider');
    element.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    var element = document.getElementById('glider');
    element.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll(event) {
    var parentElement = document.querySelector('.news-section');
    var gliderElement = document.getElementById('glider');
    var gliderRect = gliderElement.getBoundingClientRect();
    var element = document.querySelector(".glider-track");
    var rect = element.getBoundingClientRect();
    var currentX = rect.x - gliderRect.x;
    var maxX = rect.width - gliderRect.width;
    var horizontalLine = parentElement.querySelector('hr.horizontal-bar');
    var lineRect = horizontalLine.getBoundingClientRect();
    var horizontalBar = parentElement.querySelector('hr.heading');
    var barRect = horizontalBar.getBoundingClientRect();
    var actualMaxX = lineRect.width - barRect.width;
    currentX = Math.abs(currentX);
    var percent = (currentX / maxX) * actualMaxX;
    horizontalBar.style.marginLeft = "" + percent + 'px';
  }
}

export default NewsSection
