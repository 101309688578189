import React from "react";
import BaseComponent from '../BaseComponent';

import Modal from 'react-bootstrap/Modal';
import Button from '../Header/MenuButton';

class PDFView extends BaseComponent {
  render() {
    var data = this.props.data;
    return (
      <Modal
        className="pdfview"
        show={true}
        animation={false}
        onHide={this.props.closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe src={data.pdfLink + '#toolbar=0&navpanes=0'} className="pdf-iframe" frameBorder="0"></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.closeModal} name="Close" />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PDFView
