import React from "react";
import BaseComponent from '../BaseComponent';
import HexagonImageText from '../Common/HexagonImageText';
import FeatureContent from './FeatureContent';
import ProductItem from './ProductItem';
import Caution from '../Footer/Caution';

import Image1Path from 'images/device-band.png';
import Image2Path from 'images/device-patch1.png';
import Image2PathMobile from 'images/device-patch.png';
import Image3Path from 'images/app-view.jpg';

class ProductFeatures extends BaseComponent {
  getAppBullets() {
    return (
      <div className="dark-green">
        <div id="value_prop_1" className="gw-bullet-point" data-aos="fade-up" ata-aos-delay="0">Real-time alerts</div>
        <div id="value_prop_2" className="gw-bullet-point" data-aos="fade-up" ata-aos-delay="100">Monitor continuously</div>
        <div id="value_prop_3" className="gw-bullet-point" data-aos="fade-up" ata-aos-delay="200">Capture historical data</div>
      </div>
    );
  }
  render() {
    return (
      <div className="container section pb-5 pt-5 transparent-background">
        <div className="row justify-content-center flex-vcenter">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pad-2 text-center">
            <h1 className="d-none d-md-block gw-dash-heading bold mt-5 dark-green mt-5">Designed for Continuous Monitoring</h1>
            <h1 className="d-block d-md-none gw-subheading bold mt-5 dark-green mt-5">
              Designed<br />
              for<br />
              Continuous<br />
              Monitoring
            </h1>
          </div>
        </div>
        <FeatureContent
          image={Image3Path}
          heading="Tailored to deliver alerts immediately."
          body={this.getAppBullets()}
        />
        <div className="row justify-content-center flex-vcenter">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pad-2 text-center">
            <div className="d-none d-md-block mt-5" />
            <h1 className="gw-subheading bold dark-green mb-5 pb-5">Wearable options to suit your lifestyle.</h1>
            <div className="d-block d-md-none pt-5" />
          </div>
        </div>
        <Caution />
      </div>
    );
  }

  commentedOut() {
    return (
      <div className="row justify-content-center">
        <ProductItem image={Image1Path} delay={0} heading="Keep your levels in plain view" heading2="on your wrist." body={null} />
        <ProductItem class="d-none d-md-block" delay={100} image={Image2Path} heading="Stick it to your torso with ease." body={null} />
        <ProductItem class="d-block d-md-none pb-5" delay={100} image={Image2PathMobile} heading="Stick it to your torso with ease." body={null} />
      </div>
    )
  }
}

export default ProductFeatures
