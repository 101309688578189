import React from "react";
import BaseComponent from '../BaseComponent';

class FeaturedCard extends BaseComponent {
  render () {
    return (
      <a href={this.props.link} target="_blank" className="col flex-center mt-4">
        <div className="">
          <div className="featured-card flex-center" data-aos="fade-up-left" data-aos-delay={this.props.animationDelay}>
            <img src={this.props.imageSrc} style={{ width: "100px" }} />
          </div>
        </div>
      </a>
    );
  }
}

export default FeaturedCard
