import React from "react";
import BaseComponent from './BaseComponent';
import NavBar from './Header/NavBar';
import Banner from './Banner/Banner';
import ContactForm from './ConnectPage/ContactForm';
import ContactInfo from './ConnectPage/ContactInfo';
import OfficeMap from './ConnectPage/OfficeMap';
import StayConnected from './Footer/StayConnected';
import Footer from './Footer/Footer';

class ConnectPage extends BaseComponent {
  render () {
    return (
      <div>
        <NavBar currentPage='connect' />
        <Banner category='' heading='Connect' />
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pad-2">
              <ContactForm captchaSiteKey={this.props.captchaSiteKey} />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pad-2">
              <ContactInfo />
            </div>
          </div>
        </div>
        <OfficeMap />
        <StayConnected />
        <Footer />
      </div>
    );
  }
}

export default ConnectPage
