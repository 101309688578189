import React from "react";
import BaseComponent from '../BaseComponent';

class FeatureContent extends BaseComponent {
  render() {
    return (
      <div className="row mb-5 mt-5 pt-5 pb-5">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 text-center">
          <div className='section inline-block'>
            <img src={this.props.image} id="card_image" className="img-fluid" />
            <svg height="20" width="20" className="blinking dot-position">
              <circle cx="10" cy="10" r="5.5" fill="black" />
            </svg>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 card_text_container">
          <div className="card__text pad-2 pt-5 pb-5 height-of-parent">
            <div className="mt-5">
              <div className="d-none d-md-block pt-4">
                <h4 className="light-color mb-1">{this.props.category}</h4>
                <div className="gw-subtitle dark-green">
                  {this.props.heading}
                </div>
                <div className='mt-4 dark-color' style={{ "paddingTop": "1rem" }}>
                  {this.props.body}
                </div>
              </div>
              <div className="d-block d-md-none text-center">
                <h4 className="light-color mb-1">{this.props.category}</h4>
                <div className="gw-subtitle dark-green">
                  {this.props.heading}
                </div>
                <div className='mt-4 dark-color'>
                  {this.props.body}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeatureContent
