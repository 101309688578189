import React from "react";
import BaseComponent from '../BaseComponent';
import Button from '../Common/Button';

import ImagePath from 'images/lab1.jpg';

class AboutTeam extends BaseComponent {
  render() {
    return (
      <div className="container hide-overflow-x">
        <div className="row align-items-center justify-content-center height-of-parent">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 pad-2">
            <img src={ImagePath} className='img-fluid' />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 pad-2">
            <div className="mt-5 mb-5">
              <h1 className="gw-subheading mb-5 dark-green">
                Science is what we do.
              </h1>
              <p className='gw-paragraph gw-lighter mb-3 dark-green'>
                We founded GraphWear in 2015 to diagnose and monitor chronic conditions,
                and to do so without any need to test blood or urine. Our mission
                is to make this technology accessible to everyone on the planet.
                We understand the struggles inherent in managing chronic conditions:
                from the pain of regular needle pricks to the high cost of legacy
                treatments.
              </p>
              <p className='gw-paragraph gw-lighter pb-5 dark-green'>
                We're excited to have developed our investigational
                technology that continuously monitors glucose levels without any
                needles. And we're working around the clock to get our wearable
                device into the hands of those who need it most as quickly
                as possible.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutTeam
