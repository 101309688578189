import React from "react";
import BaseComponent from '../BaseComponent';

class Caution extends BaseComponent {
  render () {
    return (
      <div className='caution_bar pt-2 pb-2'>
        <div className='container'>
          <div className={`text-center pad-2 mb-3 fine-print ${this.props.class}`}>
            * Caution - INVESTIGATIONAL DEVICE, LIMITED BY UNITED STATES LAW TO INVESTIGATIONAL USE. NOT AVAILABLE FOR SALE IN THE U.S.
          </div>
        </div>
      </div>
    );
  }
}

export default Caution;
