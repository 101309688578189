import React from "react";
import BaseComponent from '../BaseComponent';

class NewsCard extends BaseComponent {
  render () {
    var category = 'Category';
    var title = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed';
    var body = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris';
    var link = '#';
    if (this.props.data) {
      category = this.props.data.category;
      title = this.props.data.title;
      body = this.props.data.body;
      link = this.props.data.link;
    }
    return (
      <div className="mt-4 width-240" data-aos="fade-up-left" data-aos-delay={this.props.animationDelay}>
        <div className="mb-3 news-card">
          <div className="light-green ncard-category">{category}</div>
          <h4 className="mt-2 ncard-title">
            {title}
          </h4>
          <p className="mt-4 dark-color ncard-body">
            <small>
              {body}
            </small>
          </p>
          <div className="mt-4">&nbsp;</div>
          <div className="mt-4 ncard-button">
            <a href={link} target="_blank" className="btn btn-link btn-lg">Read More</a>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsCard
