import React from "react";
import BaseComponent from '../BaseComponent';
import ContactItem from './ContactItem';
import SocialIcon from '../Banner/SocialIcon';

class ContactInfo extends BaseComponent {
  render () {
    return (
      <div className="mt-5 mb-5">
        {
          // <ContactItem contactIcon="bi-envelope" title="Email:" description="info@graphwear.co" />
          // <ContactItem contactIcon="bi-telephone-outbound" title="Phone:" description="+1 (415) 999 0000" />
        }
        <ContactItem contactIcon="bi-pin-map" title="Address:" description={`953 Indiana St, San Francisco\nCA 94107, USA`} />
        <ul className="social-icons-ul-horizontal mt-5" data-aos="fade-up">
          <SocialIcon type="bi-twitter" link="https://twitter.com/GraphWear_Tech" />
          <SocialIcon type="bi-facebook" link="https://www.facebook.com/graphweartech/" />
          <SocialIcon type="bi-linkedin" link="https://www.linkedin.com/company/graphwear-technologies-inc-/" />
          <SocialIcon type="bi-envelope-fill" link="mailto:info@graphwear.co" />
        </ul>
      </div>
    );
  }
}

export default ContactInfo
