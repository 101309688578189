import React from "react";
import BaseComponent from './BaseComponent';
import NavBar from './Header/NavBar';
import MainBanner from './Banner/MainBanner';
import WearBios from './MainPage/WearBios';
import OurProduct from './MainPage/OurProduct';
import ProductFeatures from './MainPage/ProductFeatures';
import ProductValueProps from './MainPage/ProductValueProps';
import AboutUs from './MainPage/AboutUs';
import AsFeaturedOn from './MainPage/AsFeaturedOn';
import NewsSection from './MainPage/NewsSection';
import Careers from './MainPage/Careers';
import StayConnected from './Footer/StayConnected';
import Footer from './Footer/Footer';

import CookieConsent from "react-cookie-consent";

class MainPage extends BaseComponent {
  constructor(props) {
    super(props);

    this.scrollToSection = this.scrollToSection.bind(this);
  }
  render () {
    return (
      <div>
        <NavBar currentPage='home' />
        <MainBanner scrollToNextSection={ () => { this.scrollToSection('our_product') } } />
        <OurProduct />
        <ProductFeatures />
        <ProductValueProps />
        <AsFeaturedOn />
        <NewsSection />
        <Careers />
        <StayConnected />
        <Footer />
        {
          // <CookieConsent
          //   debug={true}
          //   buttonText="Close"
          //   style={{ background: '#222' }}>
          //   By continuing to use our site you are consenting to GraphWear Technologies Inc using cookies in accordance with our <a href="#">Privacy Policy</a>.
          // </CookieConsent>
        }
      </div>
    );
  }
  scrollToSection(id) {
    const yOffset = -80;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}

export default MainPage
