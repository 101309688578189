import React from "react";
import BaseComponent from '../BaseComponent';

import BannerPath from 'images/green-bg-plain.jpg';
import TeamImagePath from 'images/team5.jpg';

class AboutBanner extends BaseComponent {
  render() {
    var bgStyle = {
      background: `url(${BannerPath}) no-repeat center center`,
      backgroundSize: 'cover'
    }
    return (
      <>
        <div className="banner hide-overflow-x" style={bgStyle}>
          <div className="container text-white">
            <div className="" style={{ marginTop: '15rem', height: '450px' }}>
            </div>
          </div>
        </div>
        <div className="container text-white hide-overflow-x mb-5 pb-5" style={{ marginTop: '-450px' }}>
          <div className="row justify-content-center mt-4" style={{ textAlign: 'center' }}>
            <div className='col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8'>
              <div className="mb-3 about-us" data-aos="fade-up">
                About Us
              </div>
              <h1 className="gw-largetitle mb-5" data-aos="fade-up">
                Pioneering needle-free<br/>disease monitoring
              </h1>
            </div>
          </div>
          <div className="row ps-filter justify-content-center mt-5 pt-5 mb-5">
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
              <img src={TeamImagePath} className='img-fluid' />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutBanner
