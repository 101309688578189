import React from "react";
import BaseComponent from './BaseComponent';
import NavBar from './Header/NavBar';
import Banner from './Banner/Banner';
import CareerIntro from './CareerPage/CareerIntro';
import CurrentOpenings from './CareerPage/CurrentOpenings';
import Notice from './CareerPage/Notice'
import StayConnected from './Footer/StayConnected';
import Footer from './Footer/Footer';

class CareerPage extends BaseComponent {
  render () {
    return (
      <div>
        <NavBar currentPage='careers' />
        <Banner category='' heading='Careers' />
        <CareerIntro />
        <CurrentOpenings />
        <Notice />
        <StayConnected />
        <Footer />
      </div>
    );
  }
}

export default CareerPage
