import React from "react";
import BaseComponent from '../BaseComponent';
import JobItem from './JobItem';
import PDFView from './PDFView';

class CurrentOpenings extends BaseComponent {
  constructor(props) {
    super(props);

    this.itemClicked = this.itemClicked.bind(this);
    this.state = {
      modalVisible: false,
      jobs: [
        // { title: 'Firmware Engineer', description: 'San Francisco, CA', pdfLink: 'https://graphwear-web.s3.us-east-2.amazonaws.com/Firmware+Engineer.pdf', linkedin: '' },
        // { title: 'Sr Hardware Systems Engineer', description: 'San Francisco, CA', pdfLink: 'https://graphwear-web.s3.us-east-2.amazonaws.com/Sr+Hardware+Systems+Engineer.pdf', linkedin: '' },
        // { title: 'Electronics Technician', description: 'San Francisco, CA', pdfLink: 'https://graphwear-web.s3.us-east-2.amazonaws.com/Electronics+Technician.pdf', linkedin: '' },
        // { title: 'Mechatronics Engineer', description: 'San Francisco, CA', pdfLink: 'https://graphwear-web.s3.us-east-2.amazonaws.com/Mechatronics+Engineer.pdf', linkedin: '' }
      ]
    }
  }
  jobItems() {
    var items = [];
    var jobs = this.state.jobs;
    if (jobs.length > 0) {
      for (var i = 0; i < jobs.length; i++) {
        items.push(
          <JobItem data={jobs[i]} key={i} index={i} callback={this.itemClicked} />
        )
      }
    } else {
      items.push(
        <p className="gw-paragraph gw-lighter pb-5 dark-green" data-aos="fade-up">
          Interested in applying for a position or enquire about current openings? Send us your resume at <a className="link" href="mailto: careers@graphwear.co">careers@graphwear.co</a>
        </p>
      )
    }
    return items;
  }
  render() {
    return (
      <div className="light-grey-background">
        <div className="container pb-5 pt-5 hide-overflow">
          <div className="row">
            <div className="col">
              <div className="pad-2">
                <figure className="mt-5 mb-5">
                  <div className="display-4 dark-green mb-5" data-aos="fade-up">
                    Current Openings
                  </div>
                  <div className="job-posts">
                    {this.jobItems()}
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </div>
        {this.modalView()}
      </div>
    );
  }
  modalView() {
    if (!this.state.modalVisible) {
      return null;
    }
    return (
      <PDFView closeModal={this.itemClicked} data={this.state.jobs[this.state.currentIndex]} />
    )
  }
  itemClicked(index) {
    this.setState({
      modalVisible: !this.state.modalVisible,
      currentIndex: index
    });
  }
}

export default CurrentOpenings
