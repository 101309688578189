import React from "react";
import BaseComponent from '../BaseComponent';
import Button from '../Common/Button';

import ImagePath from 'images/green-bg-plain.jpg'
import TeamImagePath from 'images/team5.jpg';

class Careers extends BaseComponent {
  render() {
    var bgStyle = {
      background: `url(${ImagePath}) no-repeat center center`,
      backgroundSize: 'cover'
    }
    return (
      <div className="bg-dark pt-5 pb-5 hide-overflow" style={bgStyle}>
        <div className="container hide-overflow-x transparent-background">
          <div className="row align-items-center justify-content-center height-of-parent">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pad-2" data-aos="fade-up">
              <img src={TeamImagePath} className='img-fluid' />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pad-2 pt-5 pb-5">
            <figure className="text-white mt-5 mb-5">
              <div className="gw-subheading bold" data-aos="fade-up">Join Our Team.</div>
              <p className="mt-5 mb-5 gw-paragraph gw-lighter" data-aos="fade-up">
                We’re looking for energetic, creative, talented people who
                like challenges. If you’re willing to get your hands dirty and
                to try and explore ideas before assuming they’re impossible,
                you’re our kinda recruit. Together, let’s save lives from
                chronic disease.
              </p>
              <span data-aos="fade-up">
                <Button name="Join Us" btnStyle="white-color btn-outline-light" href="/careers" />
              </span>
            </figure>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Careers
