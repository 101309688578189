import React from "react";
import BaseComponent from '../BaseComponent';
import 'stylesheets/person.css'

class Investor extends BaseComponent {
  render() {
    return (
      <div className='col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 light-grey-background investor-padding'>
        <a href={this.props.link} target="_blank">
          <div className="profile-container">
            <img className="profile-img" src={this.props.image} alt="" />
          </div>
        </a>
      </div>
    );
  }
}

export default Investor
