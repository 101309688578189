import React from "react";
import BaseComponent from '../BaseComponent';
// import Person from './Person';
import PersonV2 from './PersonV2';

import Alex from 'images/team_alex.jpg';
import Trent from 'images/team_trent.jpeg';
import Antoine from 'images/team_antoine.jpg';
import Robert from 'images/team_robert.jpg';
import Faisal from 'images/team_faisal.jpg';
import Meet from 'images/team_meet.jpg';
import Raj from 'images/team_raj.jpg';
import Saurabh from 'images/team_saurabh.jpg';
import Dana from 'images/team_dana.jpg';

class LeadershipTeam extends BaseComponent {
  render() {
    return (
      <div className="pt-5 pb-5">
        <div className="container mt-5 mb-5">
          <div className="display-4 bold text-center dark-green" data-aos="fade-up">
            Leadership Team
          </div>
          <div className="ps-wrap mt-4">
            <div className="d-none d-lg-block">
              <div className="row ps-filter person-alignment">
                <PersonV2 headshot={Robert} name="Robert Blazej" designation="CEO" linkedin="https://www.linkedin.com/in/robertblazej/" />
                <PersonV2 headshot={Dana} name="Dana Cambra" designation="SVP of Engineering" linkedin="https://www.linkedin.com/in/danacambra" />
                <PersonV2 headshot={Meet} name="Meet Vora" designation="Director of Operations" linkedin="https://www.linkedin.com/in/meetvora" />
                <PersonV2 headshot={Raj} name="Rajatesh Gudibande" designation="Co-founder & Advisor" linkedin="https://www.linkedin.com/in/rajatesh-gudibande-07108423" />
              </div>
              <div className="row ps-filter person-alignment">
                <PersonV2 headshot={Saurabh} name="Saurabh Radhakrishnan" designation="Co-founder & Advisor" linkedin="https://www.linkedin.com/in/saurabhradhakrishnan" />
                <PersonV2 headshot={Antoine} name="Antoine Galand" designation="Director of Engineering" linkedin="https://www.linkedin.com/in/galandantoine" />
                <PersonV2 headshot={Faisal} name="Faisal Ali" designation="Director of Software" linkedin="https://www.linkedin.com/in/faisalali89" />
                <PersonV2 headshot={Trent} name="Trent Davalos" designation="VP of Regulatory Affairs & Quality Assuarance" linkedin="https://www.linkedin.com/in/trent-davalos-4998401/" />
              </div>
            </div>
            <div className="d-block d-lg-none">
              <div className="row ps-filter person-alignment">
                <PersonV2 headshot={Robert} name="Robert Blazej" designation="CEO" linkedin="https://www.linkedin.com/in/robertblazej/" />
                <PersonV2 headshot={Dana} name="Dana Cambra" designation="SVP of Engineering" linkedin="https://www.linkedin.com/in/danacambra" />
                <PersonV2 headshot={Meet} name="Meet Vora" designation="Director of Operations" linkedin="https://www.linkedin.com/in/meetvora" />
                <PersonV2 headshot={Raj} name="Rajatesh Gudibande" designation="Co-founder & Advisor" linkedin="https://www.linkedin.com/in/rajatesh-gudibande-07108423" />
                <PersonV2 headshot={Saurabh} name="Saurabh Radhakrishnan" designation="Co-founder & Advisor" linkedin="https://www.linkedin.com/in/saurabhradhakrishnan" />
                <PersonV2 headshot={Antoine} name="Antoine Galand" designation="Director of Engineering" linkedin="https://www.linkedin.com/in/galandantoine" />
                <PersonV2 headshot={Faisal} name="Faisal Ali" designation="Director of Software" linkedin="https://www.linkedin.com/in/faisalali89" />
                <PersonV2 headshot={Trent} name="Trent Davalos" designation="VP of Regulatory Affairs & Quality Assuarance" linkedin="https://www.linkedin.com/in/trent-davalos-4998401/" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeadershipTeam
