import React from "react";
import BaseComponent from '../BaseComponent';

class HexagonImageText extends BaseComponent {
  render () {
    return (
      <div className="col-auto hexagon-col" style={this.props.style}>
        <div className="hexagon item" data-aos={this.props.aosAnimation} data-aos-delay={this.props.animationDelay} style={{ position: 'relative' }}>
          <svg version="1.1" className="hexagon-svg" xmlns="http://www.w3.org/2000/svg" width="200" height="174" viewBox="0 0 200 174">
            <path fill="#fff" stroke="rgba(0,0,0,0.2)" strokeWidth="0.8" d="M5.000000000000001 95.26279441628824Q0 86.60254037844386 5.000000000000001 77.94228634059948L45 8.660254037844386Q50 0 60 0L140 0Q150 0 155 8.660254037844386L195 77.94228634059948Q200 86.60254037844386 195 95.26279441628824L155 164.54482671904333Q150 173.20508075688772 140 173.20508075688772L60 173.20508075688772Q50 173.20508075688772 45 164.54482671904333Z">
            </path>
            <text x="50%" y="80%">{this.props.label}</text>
          </svg>
          <img src={this.props.imageSrc} />
        </div>
      </div>
    );
  }
}

export default HexagonImageText
