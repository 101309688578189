import React from "react";
import BaseComponent from '../BaseComponent';
import Button from '../Common/Button';
import ReCAPTCHA from "react-google-recaptcha";

import Modal from 'react-bootstrap/Modal';

class ContactForm extends BaseComponent {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      showModal: false,
      recaptcha: ''
    }
  }
  render () {
    var submitState = (this.state.recaptcha === '') ? 'disabled' : '';
    return (
      <form className="mt-5 mb-5" id="contact_form">
        {this.getInputRow('text', 'Name')}
        {this.getInputRow('email', 'Email')}
        {this.getInputRow('text', 'Subject')}
        <div className="form-row mt-4">
          <textarea type="text" rows="8" className="form-control" id="message" name="message" placeholder="Message" minLength="20" required />
        </div>
        <div className="mt-4">
          <ReCAPTCHA
            sitekey={this.props.captchaSiteKey}
            onChange={this.onChange} />
        </div>
        <div className="form-row mt-4">
          <Button name="Submit" onClick={this.submitForm} rounded={`btn-rounded ${submitState}`} />
        </div>
        <input type="hidden" id="recaptcha" name="recaptcha" value={this.state.recaptcha} />
        {this.getModal()}
      </form>
    );
  }
  getInputRow(type, placeholder) {
    var id = placeholder.toLowerCase();
    return (
      <div className="form-row mt-4">
        <input type={type} className="form-control" id={id} name={id} placeholder={placeholder} required />
      </div>
    )
  }
  onChange(value) {
    this.setState({
      recaptcha: value || ''
    });
  }
  getModal() {
    if (!this.state.showModal) {
      return null;
    }
    return (
      <Modal
        show={true}
        animation={false}
        onHide={this.closeModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.modalDescription}
        </Modal.Body>
      </Modal>
    );
  }
  closeModal() {
    window.grecaptcha.reset();
    this.setState({
      showModal: false,
      recaptcha: ''
    });
  }
  checkField(id) {
    var field = document.getElementById(id);
    if (!field.reportValidity()) {
      field.focus();
      return true;
    }
    return false;
  }
  submitForm() {
    var self = this;
    if (this.checkField('name') || this.checkField('email') || this.checkField('subject') || this.checkField('message')) {
      return;
    }


    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/connect");
    xhr.onreadystatechange = function (oEvent) {
      if (xhr.readyState === 4) {
        if (xhr.status === 204) {
          self.setState({
            showModal: true,
            modalTitle: 'Message sent!',
            modalDescription: 'We will get back to you as soon as possible.'
          })
          document.getElementById("email").value = '';
        } else if (xhr.status == 422) {
          json = JSON.parse(xhr.response);
          self.setState({
            showModal: true,
            modalTitle: 'Error!',
            modalDescription: json.error
          })
        } else {
          self.setState({
            showModal: true,
            modalTitle: 'Error!',
            modalDescription: ''
          })
        }
      }
    };
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    xhr.setRequestHeader('X-CSRF-Token', csrf);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    var json = {
      name: document.getElementById("name").value,
      from: document.getElementById("email").value,
      subject: document.getElementById("subject").value,
      message: document.getElementById("message").value,
      recaptcha: document.getElementById("recaptcha").value,
      authenticity_token: csrf
    }
    xhr.send(JSON.stringify(json));
  }
}

export default ContactForm
