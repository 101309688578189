import React from "react";
import BaseComponent from '../BaseComponent';
import Caution from '../Footer/Caution';

import LogoPath from 'images/graphwear.png';

class Footer extends BaseComponent {
  render() {
    return (
      <div className="container section pt-5 pb-5">
        <div className="d-none d-md-block">
          <div className="row mb-4">
            <div className="col-5">
              <div className="flex-vcenter dark-green">
                Copyrighted 2024 | All Rights Reserved
              </div>
            </div>
            <div className="col d-flex justify-content-center">
              <img src={LogoPath} width='49px' height='44px' alt="GraphWear Logo" loading='lazy' />
            </div>
            <div className="col-5">
              <div className="flex-vcenter" style={{ float: 'right' }}>
                <a className="link" href="/privacy">Privacy Policy</a>&nbsp; | &nbsp; <a className="link" href="/terms">Terms of Use</a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none mb-5">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <img src={LogoPath} width='49px' height='44px' alt="GraphWear Logo" loading='lazy' />
            </div>
          </div>
          <div className="row d-block d-md-none mt-5 text-center">
            <div className="col">
              <a className="link" href="/privacy">Privacy Policy</a>&nbsp; | &nbsp; <a className="link" href="/terms">Terms of Use</a>
            </div>
          </div>
          <div className="row d-block d-md-none mt-4 mb-4 text-center">
            <div className="col dark-green">
              Copyrighted 2024 | All Rights Reserved
            </div>
          </div>
        </div>
        <Caution />
      </div>
    );
  }
}

export default Footer
