import React from "react";

class BaseComponent extends React.Component {
  constructor(props) {
    super(props);

    this.goto = this.goto.bind(this);
  }
  goto(e) {
    if (this.props.href) {
      location.href = this.props.href;
    }
  }
}

export default BaseComponent
