import React from "react";
import BaseComponent from '../BaseComponent';

class ContactItem extends BaseComponent {
  render () {
    return (
      <div className="row">
        <div className="flex-column align-items-start active mb-3" data-aos="fade-up">
          <div className="d-flex align-content-around">
            <div className="pt-2 pb-2 mr-2">
              <i className={`bi ${this.props.contactIcon} social-icon big dark-green`}></i>
            </div>
            <div className="height-of-patient">
              <div className="flex-vcenter">
                <div>
                  <p className="mb-1 dark-green">{this.props.title}</p>
                  <div className="gw-paragraph mb-1 dark-green">{this.props.description}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactItem
