import React from "react";
import BaseComponent from '../BaseComponent';

import ImagePath from 'images/team4.jpg';

class CareerIntro extends BaseComponent {
  renderOld() {
    return (
      <div className="container pb-5 pt-5 hide-overflow">
        <div className="row justify-content-center flex-vcenter pad-2">
          <div className="col-12 mt-5 mb-5">
            <div className="display-5 dark-green mb-4" data-aos="fade-up">
              Join us to reinvent chronic disease monitoring.
            </div>
            <p className="dark-color mt-5" data-aos="fade-up">
              We’re looking for energetic, creative, talented people who like
              challenges. If you’re willing to get your hands dirty and to try
              and explore ideas before assuming they’re impossible, you’re our
              kinda recruit. Together, let’s save lives from chronic disease.
            </p>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="container hide-overflow-x">
        <div className="row align-items-center justify-content-center height-of-parent">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 pad-2">
            <img src={ImagePath} className='img-fluid' />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 pad-2">
            <div className="mt-5 mb-5">
              <h1 className="gw-subheading mb-5 dark-green">
                Join us to reinvent chronic disease monitoring.
              </h1>
              <p className='gw-paragraph gw-lighter pb-5 dark-green'>
                We’re looking for energetic, creative, talented people who like
                challenges. If you’re willing to get your hands dirty and to try
                and explore ideas before assuming they’re impossible, you’re our
                kinda recruit. Together, let’s save lives from chronic disease.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CareerIntro
