import React from "react";
import BaseComponent from '../BaseComponent';
import Investor from './Investor';

import InvIndependence from 'images/investor-independence.png';
import InvPennMedicine from 'images/investor-penn-medicine.png';
import InvScienceCenter from 'images/investor-science-center.png';
import InvDreamIt from 'images/investor-dreamit.png';
import InvPlugAndPlay from 'images/investor-plug-and-play.png';
import InvMicroVentures from 'images/investor-micro-ventures.png';
import InvDyson from 'images/investor-dyson.png';
import InvMissionBayCapital from 'images/investor-mission-bay-capital.png';
import InvBioinnovationCapital from 'images/investor-bioinnovation-capital.png';
import InvMayfield from 'images/investor-mayfield.png';
import InvBuilders from 'images/investor-builders-vc.png';
import MedtechInnovator from 'images/investor-medtech.png'

class SelectedInvestors extends BaseComponent {
  render() {
    return (
      <div className="light-grey-background pt-5 pb-5">
        <div className="container mt-5 mb-5">
          <div className="display-4 bold text-center dark-green" data-aos="fade-up">
            Selected Partners
          </div>
          <div className="ps-wrap mt-4">
            <div className="row ps-filter justify-content-center align-items-center">
              <Investor image={InvMissionBayCapital} name="Mission Bay Capital" link="https://www.missionbaycapital.com/home2" />
              <Investor image={InvBioinnovationCapital} name="Bioinnovation Capital" link="https://www.bio-innovation.com/" />
              <Investor image={InvMayfield} name="Mayfield" link="https://www.mayfield.com/" />
              <Investor image={InvBuilders} name="Builders" link="https://www.builders.vc/" />
              <Investor image={InvIndependence} name="Independence" link="https://www.ibx.com/" />
              <Investor image={InvPennMedicine} name="Penn Medicine" link="https://www.pennmedicine.org/" />
              <Investor image={InvScienceCenter} name="Science Center" link="https://sciencecenter.org/" />
              <Investor image={InvDreamIt} name="Dreamit Health" link="https://www.dreamit.com/" />
              <Investor image={InvPlugAndPlay} name="Plug And Play" link="https://www.plugandplaytechcenter.com/" />
              <Investor image={MedtechInnovator} name="Medtech Innovator" link="https://medtechinnovator.org/" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectedInvestors
