import React from "react";
import BaseComponent from '../BaseComponent';
import Button from '../Common/Button';
import SocialIcon from '../Banner/SocialIcon';

import Modal from 'react-bootstrap/Modal';

class StayConnected extends BaseComponent {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.subscribeNow = this.subscribeNow.bind(this);
    this.state = {
      showModal: false
    }
  }
  render() {
    return (
      <div className="container pt-5 pb-5 mt-5">
        <div className="row align-items-center justify-content-center">
          <div className="display-4 text-center dark-green mb-4" data-aos="fade-up">
            Stay Connected
          </div>
        </div>
        <div className="row align-items-center justify-content-center" data-aos="fade-up">
          <ul className="social-icons-ul-horizontal mt-4">
            <SocialIcon type="bi-twitter" link="https://twitter.com/GraphWear_Tech" />
            <SocialIcon type="bi-facebook" link="https://www.facebook.com/graphweartech/" />
            <SocialIcon type="bi-linkedin" link="https://www.linkedin.com/company/graphwear-technologies-inc-/" />
            <SocialIcon type="bi-envelope-fill" link="mailto:info@graphwear.co" />
          </ul>
          <div className="gw-paragraph gw-lighter text-center dark-green mt-4 mb-4" data-aos="fade-up">
            Get Newsletter
          </div>
          <div className="col-xs-8 col-sm-8 col-md-6 col-lg-4 col-xl-4" data-aos="fade-up">
            <form className="input-group" id="form_subscribe">
              <input type="text" id="nl_email" className="form-control newsletter-input" placeholder="Email address" aria-label="Email address" aria-describedby="basic-addon2" />
              <div className="input-group-append">
                <Button name="Submit" rounded="newsletter-btn" onClick={this.subscribeNow} />
              </div>
            </form>
          </div>
        </div>
        {this.getModal()}
      </div>
    );
  }
  getModal() {
    if (!this.state.showModal) {
      return null;
    }
    return (
      <Modal
        show={true}
        animation={false}
        onHide={this.closeModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.modalDescription}
        </Modal.Body>
      </Modal>
    );
  }
  closeModal() {
    this.setState({
      showModal: false
    });
  }
  subscribeNow(event) {
    var self = this;
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/subscriptions");
    xhr.onreadystatechange = function (oEvent) {
      if (xhr.readyState === 4) {
        if (xhr.status === 204) {
          self.setState({
            showModal: true,
            modalTitle: 'Thank you!',
            modalDescription: 'Stay tuned in to receive exciting updates about GraphWear'
          })
          document.getElementById("nl_email").value = '';
        } else if (xhr.status == 422) {
          json = JSON.parse(xhr.response);
          self.setState({
            showModal: true,
            modalTitle: 'Error!',
            modalDescription: json.error
          })
        } else {
          self.setState({
            showModal: true,
            modalTitle: 'Error!',
            modalDescription: ''
          })
        }
      }
    };
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    xhr.setRequestHeader('X-CSRF-Token', csrf);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    var json = {
      email: document.getElementById("nl_email").value,
      authenticity_token: csrf
    }
    console.log(json);
    xhr.send(JSON.stringify(json));
  }
}

export default StayConnected
