import React from "react";
import BaseComponent from '../BaseComponent';

class WearBios extends BaseComponent {
  render () {
    return (
      <div className="container pb-5 pt-5 flex-vcenter hide-overflow transparent-background">
        <div className="row justify-content-center flex-vcenter">
          <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12 col-xs-12 pad-2">
            <figure className="text-center mt-5 mb-5">
              <h4 className="light-color mb-1" data-aos="fade-up">Wear bios</h4>
              <div className="gw-subtitle dark-green mb-4" data-aos="fade-up">to continuously monitor your glucose <br className="d-none d-sm-block" />levels without any needles</div>
              <p className="text-muted light-color" data-aos="fade-up">bios is currenly being tested in the clinic *</p>
              <p className="dark-color mt-5" data-aos="fade-up">
                Continuous glucose monitoring (CGM) is essential for diabetic patients to manage
                diabetes and prevent the onset of other chronic diseases (heart disease, etc).
                Of the 34 million people who suffer from diabetes in the USA, less than 1.5 million
                have access to CGMs because they are unaffordable (~$4000/year) and requires
                frequent needle insertion. GW's needle-free CGM system can measure glucose from
                the surface of the skin without pain or inconvenience. Patients can afford it
                without insurance. This empowers patients to intervene with timely insulin dosage
                and medication to prevent hypo- and hyper-glycemic events and make health decision
                by sharing the data with their primary care provider
              </p>
            </figure>
          </div>
        </div>
      </div>
    );
  }
}

export default WearBios
