import React from "react";
import BaseComponent from '../BaseComponent';
import Button from '../Common/Button';
import SocialIcon from '../Banner/SocialIcon';

class JobItem extends BaseComponent {
  render() {
    var data = this.props.data;
    return (
      <div className="flex-column job-card align-items-start active mt-3 mb-3" data-aos="fade-up-left" onClick={ () => { this.props.callback(this.props.index) } } style={{ cursor: 'pointer' }}>
        <div className="d-flex justify-content-between">
          <div className="height-of-patient">
            <div className="flex-vcenter">
              <div>
                <h4 className="mb-1 dark-green">{data.title}</h4>
                <p className="mb-1 light-color">{data.description}</p>
              </div>
            </div>
          </div>
          <div className="flex-vcenter pt-2 pb-2">
            {
              // <SocialIcon type="bi-linkedin" link={data.linkedin} />
            }
            <SocialIcon type="bi-file-earmark-pdf" modal="true" />
          </div>
        </div>
      </div>
    );
  }
}

export default JobItem
