import React from "react";
import BaseComponent from '../BaseComponent';
import Caution from '../Footer/Caution';

// import ImagePath from 'images/what-we-have.jpg';
import ImagePath from 'images/green-bg-plain.jpg';

import Image1Path from 'images/1.png';
import Image2Path from 'images/2.png';
import Image3Path from 'images/3.png';
import Image4Path from 'images/4.png';
import Image5Path from 'images/5.png';
import Image6Path from 'images/6.png';
import Image7Path from 'images/7.png';
import Image8Path from 'images/8.png';
import Image9Path from 'images/9.png';
import Image10Path from 'images/10.png';

class ProductValueProps extends BaseComponent {
  getImages() {
    var imagePaths = [
      Image1Path, Image2Path, Image3Path, Image4Path, Image5Path,
      Image6Path, Image7Path, Image8Path, Image9Path, Image10Path
    ];
    var elements = [];
    for (var i = 0; i < imagePaths.length; i++) {
      elements.push(
        <div className="sticky" key={i} style={{ "zIndex": 98 - i }}>
          <img src={imagePaths[i]} width="300px" height="120px"  />
        </div>
      )
    }
    elements.push(
      <div key="PVPLast">
        <div className="d-block d-lg-none mb-5" />
        <div className="d-none d-lg-block d-xl-none" style={{ "paddingBottom": "450px" }} />
        <div className="d-none d-lg-none d-xl-block" style={{ "paddingBottom": "350px" }} />
      </div>
    );
    return elements;
  }
  render() {
    var bgStyle = {
      background: `url(${ImagePath}) no-repeat center center`,
      backgroundSize: 'cover'
    }
    return (
      <>
        <div className="dark-background section pt-5 pb-5">
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center mb-5">
                {this.getImages()}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-5">
                <h1 className="d-block d-md-none gw-largetitle bold sticky text-white mt-5 pad-1-5 pb-5">
                  No-Needle,<br />No-Blood<br />investigational platform<br />that could provide<br />biometrics for<br />many diseases.
                </h1>
                <h1 className="d-none d-md-block gw-subheading bold sticky text-white">
                  No-Needle,<br />No-Blood<br />investigational platform<br />that could provide<br />biometrics for<br />many diseases.
                </h1>
              </div>
            </div>
          </div>
          <Caution class="fine-print-gb"/>
        </div>
      </>
    );
  }
}

export default ProductValueProps
