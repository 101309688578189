import React from "react";
import BaseComponent from '../BaseComponent';

class SocialIcon extends BaseComponent {
  render() {
    if (this.props.modal === "true") {
      return (
        <a onClick={ () => { this.props.callback(this.props.link) } }>
          <i className={`bi ${this.props.type} social-icon`}></i>
        </a>
      );
    }
    return (
      <a href={this.props.link} target="_blank">
        <i className={`bi ${this.props.type} social-icon`}></i>
      </a>
    );
  }
}

export default SocialIcon
