import React from "react";
import BaseComponent from '../BaseComponent';

import BannerPath from 'images/green-bg-plain.jpg';

class Banner extends BaseComponent {
  render() {
    var bgStyle = {
      background: `url(${BannerPath}) no-repeat center center`,
      backgroundSize: 'cover'
    }
    return (
      <div className="banner hide-overflow-x" style={bgStyle}>
        <div className="container text-white">
          <div className="" style={{ marginTop: '15rem'}}>
            <div className="flex-row pad-2">
              <h2 className="mb-1" data-aos="fade-up">
                {this.props.category}
              </h2>
              <h1 className="d-block d-md-none gw-title mb-5" data-aos="fade-up">
                {this.props.heading}
              </h1>
              <h1 className="d-none d-md-block gw-subheading mb-5" data-aos="fade-up">
                {this.props.heading}
              </h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner
