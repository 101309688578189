import React from "react";
import BaseComponent from './BaseComponent';
import NavBar from './Header/NavBar';
import Banner from './Banner/Banner';
import ContactForm from './ConnectPage/ContactForm';
import ContactInfo from './ConnectPage/ContactInfo';
import OfficeMap from './ConnectPage/OfficeMap';
import StayConnected from './Footer/StayConnected';
import Footer from './Footer/Footer';

class ConnectPage extends BaseComponent {
  render () {
    return (
      <div>
        <NavBar currentPage='' />
        <Banner category='' heading='Terms Of Use' />
        <div className="container mt-5 mb-5">
          <div className="row pad-2 pt-5 dark-green">
            <p>Welcome, and thank you for your interest in GraphWear Technologies, Inc. (“GraphWear,” “we,” or “us”) and our website at www.graphwear.co (the “Site”). These Terms of Use are a legally binding contract between you and GraphWear regarding your use of the Site.</p>

            <h1 className="mt-4 mb-3">PLEASE READ THE FOLLOWING TERMS CAREFULLY</h1>
            <p>BY USING THE SITE, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SITE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING GRAPHWEAR’S PRIVACY POLICY (TOGETHER, THESE “TERMS”). IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SITE. YOUR USE OF THE SITE, AND GRAPHWEAR’S PROVISION OF THE SITE TO YOU, CONSTITUTES AN AGREEMENT BY GRAPHWEAR AND BY YOU TO BE BOUND BY THESE TERMS.</p>

            <h1 className="mt-4 mb-3">1. GraphWear Site Overview.</h1>
            <p>GraphWear provides a platform accessible through the Site for interested viewers to learn about GraphWear developments and product offerings, and to submit contact information to receive information regarding: our diagnostics platforms (including the CRISPR-based DETECTR BOOSTTM SARS-COV-2 Kit), gene editing platforms, protein discovery, and careers.</p>

            <h1 className="mt-4 mb-3">2. Eligibility</h1>
            <p>You must be at least 18 years old to use the Site. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 18 years old; (b) you have not previously been suspended or removed from the Site; and (c) your use of the Site is in compliance with any and all applicable laws and regulations. If you are an entity, organization, or company, the individual accepting these Terms on your behalf represents and warrants that they have authority to bind you to these Terms and you agree to be bound by these Terms.</p>

            <h1 className="mt-4 mb-3">3. Ownership; Proprietary Rights.</h1>
            <p>The Site is owned and operated by GraphWear. The visual interfaces, graphics, design, compilation, information, data, computer code (including source code or object code), products, software, services, and all other elements of the Site (“Materials”) provided by GraphWear are protected by intellectual property and other laws. All Materials included in the Site are the property of GraphWear or its third party licensors. Except as expressly authorized by GraphWear, you may not make use of the Materials. GraphWear reserves all rights to the Materials not granted expressly in these Terms.</p>

            <h1 className="mt-4 mb-3">4. Licenses</h1>
            <p><b>4.1 License.</b> Subject to these Terms, GraphWear grants you a limited, revocable license to access and use the Site solely for non-commercial purposes to learn more about our products and services and to review and apply for job openings. No other use of the Site is authorized.</p>

            <p><b>4.2 License Restrictions.</b> You must comply with all applicable laws when using the Site. Except as may be expressly permitted by applicable law or expressly permitted by us in writing, you will not, and will not permit anyone else to: (a) store, copy, modify, distribute, or resell any Materials or compile or collect any Materials as part of a database or other work; (b) use any automated tool (e.g., robots, spiders) to use the Site or store, copy, modify, distribute, or resell any Materials; (c) rent, lease, or sublicense your access to the Site; (d) use the Site or Materials for any purpose except for your own personal use; (e) circumvent or disable any digital rights management, usage rules, or other security features of the Site; (f) reproduce, modify, translate, enhance, decompile, disassemble, reverse engineer, or create derivative works of the Site; (g) use the Site in a manner that threatens the integrity, performance, or availability of the Site; or (h) remove, alter, or obscure any proprietary notices (including copyright notices) on any portion of the Site or Materials.</p>

            <p><b>4.3 Feedback.</b> If you choose to provide input and suggestions regarding problems with or proposed modifications or improvements to the Site (“Feedback”), then you hereby grant GraphWear an unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to exploit the Feedback in any manner and for any purpose, including to improve the Site and create other products and services.</p>

            <h1 className="mt-4 mb-3">5. Third Party Terms</h1>
            <p><b>5.1 Third Party Services and Linked Websites.</b> GraphWear may provide tools through the Site that enable you to export information, including Materials, to third party services, including through our implementation of third party buttons (such as “like” or “share” buttons). By using one of these tools, you agree that GraphWear may transfer that information to the applicable third party service. Third party services are not under GraphWear’s control, and, to the fullest extent permitted by law, GraphWear is not responsible for any third party service’s use of your exported information. The Site may also contain links to third party websites. Linked websites are not under GraphWear’s control, and GraphWear is not responsible for their content.</p>

            <p><b>5.2 Third Party Software.</b> The Site may include or incorporate third party software components that are generally available free of charge under licenses granting recipients broad rights to copy, modify, and distribute those components (“Third Party Components”). Although the Site is provided to you subject to these Terms, nothing in these Terms prevents, restricts, or is intended to prevent or restrict you from obtaining Third Party Components under the applicable third party licenses or to limit your use of Third Party Components under those third party licenses.</p>

            <h1 className="mt-4 mb-3">6. Communications</h1>
            <p>We may send you emails concerning our products and services, as well as those of third parties. You may opt out of promotional emails by following the unsubscribe instructions in the promotional email itself.</p>

            <h1 className="mt-4 mb-3">7. Indemnity</h1>
            <p>You will indemnify and hold GraphWear, and affiliates, officers, agents, and employees, harmless from any costs, damages, expenses, and liability caused by your use of the Site or Materials, your violation of these Terms, or your violation of any rights of a third party through use of the Site. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to cooperate with our defense of those claims.</p>

            <h1 className="mt-4 mb-3">8. Disclaimers; No Warranties</h1>
            <p>THE SITE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SITE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. GRAPHWEAR DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SITE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SITE, INCLUDING: (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. GRAPHWEAR DOES NOT WARRANT THAT THE SITE OR ANY PORTION OF THE SITE, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE SITE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND GRAPHWEAR DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.</p>

            <p>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SITE OR GRAPHWEAR ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SITE WILL CREATE ANY WARRANTY REGARDING ANY OF THE GRAPHWEAR ENTITIES OR THE SITE THAT IS NOT EXPRESSLY STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE SITE AND YOUR DEALING WITH ANY OTHER SITE USER. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SITE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE SITE) OR ANY LOSS OF DATA, INCLUDING MATERIALS.</p>

            <p>THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. GraphWear does not disclaim any warranty or other right that GraphWear is prohibited from disclaiming under applicable law.</p>

            <h1 className="mt-4 mb-3">9. Limitations of Liability</h1>
            <p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE GRAPHWEAR ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SITE OR ANY MATERIALS OR CONTENT ON THE SITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY GRAPHWEAR ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.</p>

            <p>TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE GRAPHWEAR ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SITE OR OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO $100.</p>

            <p>EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 9 WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</p>

            <h1 className="mt-4 mb-3">10. Miscellaneous</h1>
            <p><b>10.1 General Terms.</b> These Terms, together with the Privacy Policy and any other agreements expressly incorporated by reference into these Terms, are the entire and exclusive understanding and agreement between you and GraphWear regarding your use of the Site. You may not assign or transfer these Terms or your rights under these Terms, in whole or in part, by operation of law or otherwise, without our prior written consent. We may assign these Terms at any time without notice or consent. The failure to require performance of any provision will not affect our right to require performance at any other time after that, nor will a waiver by us of any breach or default of these Terms, or any provision of these Terms, be a waiver of any subsequent breach or default or a waiver of the provision itself. Use of section headers in these Terms is for convenience only and will not have any impact on the interpretation of any provision. Throughout these Terms the use of the word “including” means “including but not limited to”. If any part of these Terms is held to be invalid or unenforceable, the unenforceable part will be given effect to the greatest extent possible, and the remaining parts will remain in full force and effect.</p>

            <p><b>10.2 Governing Law.</b> These Terms are governed by the laws of the State of California without regard to conflict of law principles. You and GraphWear submit to the personal and exclusive jurisdiction of the state courts and federal courts located within San Mateo County, California for resolution of any lawsuit or court proceeding permitted under these Terms. We operate the Site from our offices in California, and we make no representation that Materials included in the Site are appropriate or available for use in other locations.</p>

            <p><b>10.3 Privacy Policy.</b> Please read the GraphWear Privacy Policy graphwear.co/privacy/ carefully for information relating to our collection, use, storage, disclosure of your personal information. The GraphWear Privacy Policy is incorporated by this reference into, and made a part of, these Terms.</p>

            <p><b>10.4 Additional Terms.</b> Your use of the Site is subject to all additional terms, policies, rules, or guidelines applicable to the Site or certain features of the Site that we may post on or link to from the Site (the “Additional Terms”). All Additional Terms are incorporated by this reference into, and made a part of, these Terms.</p>

            <p><b>10.5 Consent to Electronic Communications.</b> By using the Site, you consent to receiving certain electronic communications from us as further described in our Privacy Policy. Please read our Privacy Policy to learn more about our electronic communications practices. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that those communications be in writing.</p>

            <p><b>10.6 Contact Information.</b> The Site is offered by GraphWear Technologies, Inc., located at 953 Indiana St San Francisco, CA 94107. You may contact us by sending correspondence to that address or by emailing us at privacy@graphwear.co.</p>

            <p><b>10.7 No Support.</b> We are under no obligation to provide support for the Site. In instances where we may offer support, the support will be subject to published policies.</p>

            <p><b>10.8 International Use.</b> The Site is intended for visitors located within the United States. We make no representation that the Site is appropriate or available for use outside of the United States. Access to the Site from countries or territories or by individuals where such access is illegal is prohibited.</p>
          </div>
        </div>
        <StayConnected />
        <Footer />
      </div>
    );
  }
}

export default ConnectPage
