import React from "react";
import BaseComponent from '../BaseComponent';
import FeaturedCard from './FeaturedCard';

import TechCrunchPath from 'images/featuredon_techcrunch.png';
import TechnicallyPath from 'images/featuredon_technically.jpeg';
import BoldBusinessPath from 'images/featuredon_boldbusiness.png';
import MedCityPath from 'images/featuredon_medcitynews.png';
import MobiHealthPath from 'images/featuredon_mobihealthnews.png';

class AsFeaturedOn extends BaseComponent {
  render () {
    return (
      <div className="light-grey-background flex-vcenter min-360h hide-overflow">
        <div className="container pt-5 pb-5">
          <div className="display-4 mt-5 mb-4 text-center dark-green" data-aos="fade-up">
            As Featured On
          </div>
          <div className="row mt-5 mb-5 justify-content-md-center">
            <FeaturedCard imageSrc={TechCrunchPath} link="https://techcrunch.com/2021/10/04/graphwear-closes-20-5m-series-b-for-a-needle-free-nanotech-powered-glucose-monitor/" animationDelay="0" />
            <FeaturedCard imageSrc={TechnicallyPath} link="https://technical.ly/philly/2018/04/17/graphwear-technologies-4m-series-a/" animationDelay="0" />
            <FeaturedCard imageSrc={BoldBusinessPath} link="https://www.boldbusiness.com/health/sensor-diagnoses-diseases-from-sweat/" animationDelay="100" />
            <FeaturedCard imageSrc={MedCityPath} link="https://medcitynews.com/2016/10/graphwear-sweat-sensor-wearable-and-nfl/" animationDelay="200" />
            {
              // <FeaturedCard imageSrc={MobiHealthPath} link="https://www.mobihealthnews.com/45343/new-dreamit-class-targets-epilepsy-cancer-concussions-and-more" animationDelay="300" />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default AsFeaturedOn
